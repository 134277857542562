import React from "react";

const Logo = ({ img, height, width, styles }) => {
    return (
        <img
            src={img}
            width={width}
            height={height}
            style={styles}
            alt="logo"
        />
    );
};

export default Logo;
