import React, {useState} from 'react';

import { useHistory } from "react-router-dom";
import Logo from '../../components/branding/logo/Logo';
import LogoRioSantiago from '../../components/branding/assets/logoRioSantiago.svg';
import { SignIn } from '../../api/Services';

import '../../components/modals/ModalSubirArchivo.css';

const ModalLogin = ({}) =>{
  const [username, setUsername] = useState(``);
  const [password, setPassword] = useState(``);
  const [loggingIn, setLoggingIn] = useState(false);

  const history = useHistory();

  const checkUsername = (event) => {
    const { value } = event.target;
    setUsername(value);
  };

  const checkPass = (event) => {
    const value = event.target.value.trim();
    setPassword(value);
    
  };

  const handleKeyDown = (event) => {
    if (event.key === `Enter`) {
        login();
    }
  };

  const login = () =>{
    let path = `/gestion`; 

    setLoggingIn(true);
    SignIn(username, password).then(response => {
      if (response.status === 200) {
        history.push(path);
      } 
      setLoggingIn(false);
    })
    .catch( (e)=>{
      console.log(e, 'error');
      setLoggingIn(false);
    })
  }
  return (
    <div className="modalContainer" style={{display:"inherit" }}>
      <div  
        style={{
          paddingLeft: "3%",
          paddingTop: "2%",display:"flex"
        }}
      >
        <Logo 
          img={LogoRioSantiago} 
          width={"20%"} 
          height={"15%"}
          style={{
            marginTop: "3%",
            marginleft: "5%",
            marginTop:"40px",
            margin: 0,
            paddingTop: "10%",
            marginleft: "-30%"
          }}
        /> 
      </div>
      <div className="boxModal" style={{marginTop:"3%"}}>
          <p style={{color:"#002F5D", fontSize:"35px"}}>Ingresa tu usuario</p>
          <form action="">
              <input 
                type="text" 
                class="input-text" 
                placeholder="Correo" 
                required
                value={username}
                onChange={checkUsername}
                /> 
              <input 
                type="password" 
                class="input-text" 
                placeholder="Contraseña" 
                required
                value={password}
                onChange={checkPass}
                onKeyDown={handleKeyDown}/>
          </form>
          <div className="buttonOkModal" 
            onClick={login}
          >{loggingIn ? "Procesando Información" : "Enviar"}</div>
      </div>
    </div>
  )
}

export default ModalLogin;