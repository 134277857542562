import React from "react";
import "./Charts.css"
import Chart from "./Chart";

import HorizontalParagraph from '../texts/HorizontalParagraph';
import Logo from '../branding/logo/Logo';
import LogoJalisco from '../branding/assets/logoJalisco.svg';
import CircularProgressBar from './CircularProgressBar';

import LeftArrow from '../../components/branding/assets/leftArrow.svg';

const ChartsScreen = ({ 
    showMoreCharts, 
    useWidth, 
    useHeight, 
    estacion, 
    click, 
    icaValueChart,
    icaPorEstacion,
    date,
    privateView,
    marginTop,
    icaConMesesDelUltimoAño,
    icaEstacionesUltimoAno,
    icaConMesesDelAntepenultimoAño,
    estaciones,
    estacionPorNombreIcaAño,
    antepenultimoAñoValue,
    icaPorMesEstacionUltimoAno,
    icaPorMesEstacionPenUltimoAno,
    goBack,
    showEstrategia
    
}) => {
    const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ]
    let lastYear;
    let ancestorYear;
    let anualAverage;
    let antepenultimoAño = Number.parseFloat([antepenultimoAñoValue[0]?.ica]).toFixed(1); 
    let arregloAcomodado =  icaConMesesDelUltimoAño.sort(function(a, b) {
        return a.month - b.month;
    });

    let arregloAcomodadoAntepenultimoAño = icaConMesesDelAntepenultimoAño.sort(function(a, b) {
        return a.month - b.month;
    });

    const arrUltimoAño = [...arregloAcomodado]?.map(function(val, i){
        return val?.ica ;
    });

    const arrUltimoAñoPorEstacion = [...icaPorMesEstacionUltimoAno]?.map(function(val, i){
        return val?.ica ;
    });

    const arrAntpenultimoAñoMeses = [...arregloAcomodadoAntepenultimoAño]?.map(function(val, i){
        return val.month;
    });

    let count = 12;
    let missing = [];
    let nuevoArrayConDatosUndefined;

    for (var i = 1; i <= count; i++) {
        if (arrAntpenultimoAñoMeses.indexOf(i) === -1) {
            missing.push({year: ancestorYear, month: i, ica: undefined});
        }  
    }

   const mapeoFaltantes =  missing?.map(function(val){
        return val;
    });

    nuevoArrayConDatosUndefined = icaConMesesDelAntepenultimoAño.concat(mapeoFaltantes);

    arregloAcomodadoAntepenultimoAño = nuevoArrayConDatosUndefined.sort(function(a, b) {
        return a.month - b.month;
    });

    const arrAntpenultimoAño = [...arregloAcomodadoAntepenultimoAño]?.map(function(val, i){
        return val?.ica ;
    });
   
    const icaPorEstacionmap = [...icaPorEstacion]?.map(function(val, i){
        return val?.ica;
    });

    const icaEstacionesUltimoAño = [...estaciones]?.map(function(val, i){
        return val?.name;
    });

    const icaPorEstacionDelUltimoAñoValor = [...estaciones]?.map(function(val, i){
        return val?.ica;
    });

    [...icaValueChart]?.map(function(val){
        lastYear = [icaValueChart[0]?.year]
        ancestorYear = lastYear-1
       
        if(Number.parseFloat([icaValueChart[0]?.ica]).toFixed(1) ){
            anualAverage =  Number.parseFloat([icaValueChart[0]?.ica]).toFixed(1); 
        }
        return val.ica;
    });

    const condicionFromValue = (value)=>{
        const classes = [
            {label:"Muy mala", color:"#F41C1C"},
            {label:"Mala", color:"#FF9439"},
            {label: "Media", color:"#FAD14A"},
            {label: "Buena", color:"#2BE063"},
            {label:  "Excelente", color:"#2A7BEA"} 
        ];
        let clasificacion = 0;
        if(25 < value && value <= 50) clasificacion=1;
        if(50 < value && value <= 70) clasificacion=2;
        if(70 < value && value <= 90) clasificacion=3;
        if(90 < value && value <= 100) clasificacion=4;

        return classes[clasificacion];

    }

    let ica = Math.round(parseInt(estacion?.ica));
    let icaConDecimales =Number.parseFloat(estacion?.ica).toFixed(1);
    let Excelente = ica > 100  
        ? {label:  "Excelente", color:"#2A7BEA"} 
        : {label: "Buena", color:"#2BE063"};

    let buenaCalidad =  ica > 70  
        ? Excelente 
        : {label: "Media", color:"#FAD14A"};

    let malaCalidad = ica <=25  
        ? {label:"Muy mala", color:"#F41C1C"} 
        : {label:"Mala", color:"#FF9439"}

    const condicion = ica <= 50 ? malaCalidad : buenaCalidad;

    const style = { showMoreCharts };
    const windowWidth = { useWidth };
    const clickStyles = { width: style.showMoreCharts ? "30%" : "40%", marginLeft: style.showMoreCharts ? "70%" : "60%", height: useHeight, top: marginTop }
   
    let dataAntepenultimoAño = click ? icaPorMesEstacionPenUltimoAno : arrAntpenultimoAño;
    let dataAnualLastYear = click != "" ?  icaPorMesEstacionUltimoAno : arrUltimoAño;

    const yearAverage = (data)=>{
        let CNT=0; 
        let ICAsum=0.0;
        let icaAverage = Number.parseFloat(0.0).toFixed(1); 
        if(Array.isArray(data)){
            data?.forEach(element => {
                if(typeof element=="undefined" )return; 
                CNT++;
                ICAsum+=Number.parseFloat(element);
            });
            icaAverage = Number.parseFloat(ICAsum/CNT); 
        }
        return icaAverage;
    }

    let icaAverage = Number.parseFloat(0.0).toFixed(1);
    let icaPreAverage = Number.parseFloat(0.0).toFixed(1);
    if(click && Array.isArray(dataAnualLastYear) && Array.isArray(dataAntepenultimoAño)){
        icaAverage = yearAverage(dataAnualLastYear);
        icaPreAverage = yearAverage(dataAntepenultimoAño);
    }

    let labelGrafica1 = "Evolución anual en Rio Santiago del ICA para "  
    let labelGrafica2 = "Comparación estaciones de monitoreo con valores promedio del ICA para "    
    let labelGrafica3 = "Evolución anual del ICA del Río Santiago para  "
    return (
        <div className="viewChartConponent" style={
            windowWidth.useWidth > 600 ? clickStyles : {  }}>
            <div className="containerWithShadow">
                <div className="headerChartsText">
                {useWidth < 800 && click !== "" && !showEstrategia &&
                    <div onClick={goBack} style={{justifyContent:"flex-end", display:"flex", marginTop:"-15px"}}>
                        <img width="25px" alt="Mostrar gráficas" className="goBack"
                            src={LeftArrow} />
                        <p style={{paddingInline:"5px", color: "#8ACCE2"}} className="goBackText">Regresar</p>
                    </div>}
                    {!click && privateView &&
                        <div  
                            style={{backgroundColor:"#244C71",
                                fontWeight: "bold",
                                height: "31px",
                                marginLeft: "-20px",
                                width: "300px",
                                borderTopRightRadius: "20px",
                                marginTop: "36px",
                                color:"#FFFFFF", 
                                display: "flex",
                                justifyContent: "space-around",
                        }}>
                            <p>Datos generales</p>
                            <p>{date.split(" ")[0].split("-").reverse().join("/")}</p>
                        </div> }
                        {!click && !privateView && <div>
                            <p className="boldTextCharts">Datos generales</p>
                            <p style={{
                                color:"black",
                                marginTop: -3,
                                fontWeight: 'bold',
                                fontsize: 12
                                }}>{date.split(" ")[0].split("-").reverse().join("/")}</p>
                        </div>
                    }
                    <p className="boldTextCharts">{click && !privateView && "Datos de la estación"}</p>
                    <p className="municipioTextBold">{estacion?.description}</p>
                    <p className="dateText">{estacion?.date?.split(" ")[0].split("-").reverse().join("/")}</p>
                    {click && icaAverage > 0.0 &&
                        <div className="boxContainer" style={{marginBottom:'1.5em'}}>
                            <div className="boxN" style={{ backgroundColor: "white" }}>
                                <p className="textBox" style={{ color: "#002445" }}>Calidad del agua</p>
                                <p className="calidadText" style={{ backgroundColor: condicion.color, width:'50%', margin:'1em auto' }}>{condicion?.label}</p>
                            </div>
                            <div className="boxN" style={{ backgroundColor: condicion.color }}>
                                <p className="textNumberBox" style={{ color: "white", marginTop:"15px" }}>{icaAverage.toFixed(1)}</p> 
                            </div>
                        </div>
                    }
                    <div className="boxContainer">
                        <div className="boxN" style={{ backgroundColor: "white" }}>
                            <p className="textBox" style={{ color: "#002445" }}>Promedio ICA año</p>
                            <p className="textNumberBox" style={{ color: "#005FC5" }}>{lastYear}</p>
                        </div>

                        <div className="boxN" style={{ backgroundColor: condicionFromValue(anualAverage).color, opacity:'0.75' }}>
                            <p className="textNumberBox" style={{ color: "white", marginTop:"15px" }}>{anualAverage}</p> 
                        </div>
                    </div>
                </div>
                <div className="chartContainer">
            
                    <HorizontalParagraph
                        paragraph={labelGrafica1  + lastYear}
                    />
                    <Chart
                        data={{
                            labels:meses, 
                            datasets: [
                                {
                                    label: "Promedio mensual ICA",
                                    data:  [...dataAnualLastYear],
                                    fill: false,
                                    backgroundColor: "rgb(186, 185, 87)",
                                    borderColor: "rgb(186, 185, 87, 0.2)",
                                },
                            ],
                            options: {
                                responsive: true,
                                legend: {
                                display: false
                                },
                                animation: {
                                    duration: 2000,
                                },
                            }
                        }}
                        type="line"></Chart>
                </div> 
                {click != "" 
                ?<div className="chartCirleContainer">
                    <HorizontalParagraph
                        paragraph={labelGrafica2 + ancestorYear + " y " + lastYear}
                    />
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop:"10%"
                    }}>
                        <div className="boxContainer" style={{marginBottom:'1.5em', width:'100%'}}>
                            <div className="boxN" style={{ backgroundColor: "white" }}>
                                <p className="textBox" style={{ color: "#002445" }}>ICA {ancestorYear}</p>
                                { icaPreAverage > 0.0 && <p className="calidadText" style={{ width:'50%', margin:'1em auto', backgroundColor: condicionFromValue(icaPreAverage).color }}>{icaPreAverage.toFixed(4)}</p>}                            </div>
                            <div className="boxN" style={{ backgroundColor: "white" }}>
                                <p className="textBox" style={{ color: "#002445" }}>ICA {lastYear}</p>
                                { icaAverage > 0.0 && <p className="calidadText" style={{ width:'50%', margin:'1em auto', backgroundColor: condicionFromValue(icaAverage).color }}>{icaAverage.toFixed(4)}</p> }
                            </div>
                        </div>
                    </div>
                </div>
                
                :<div className="chartContainer">
                    <HorizontalParagraph
                         paragraph={labelGrafica2 + " " +  ancestorYear  + " y " + lastYear}
                    />
                    <Chart
                        data={{
                            labels: icaEstacionesUltimoAño,
                            datasets: [
                                 {
                                    type: 'line',
                                    label: lastYear-1,
                                    backgroundColor: "rgb(186, 185, 87)",
                                    borderColor: "rgb(186, 185, 87, 0.2)",
                                    fill: false,
                                    data: estacionPorNombreIcaAño,
                                },
                                {
                                    type: 'line',
                                    label: lastYear,
                                    fill: false,
                                    backgroundColor: "rgb(75, 33, 42)",
                                    borderColor: "rgb(75, 33, 42, 0.2)",
                                    data:  icaEstacionesUltimoAno,
                                },
                            ],
                            options: {
                                responsive: true,
                                legend: {
                                display: false
                                },
                                animation: {
                                    duration: 0,
                                },
                            }
                        }}
                        type="bar"></Chart>
                </div>}

                <div className="chartContainer">
                    <HorizontalParagraph
                        paragraph={labelGrafica3 + " " +  ancestorYear  + " y " + lastYear}
                    />
                    <Chart
                        data={{
                            labels:meses,
                            datasets: [
                                {
                                    type: 'line',
                                    fill: false,
                                    label: lastYear-1,
                                    backgroundColor: "rgb(186, 185, 87)",
                                    borderColor: "rgb(186, 185, 87, 0.2)",
                                    data: [...dataAntepenultimoAño],
                                },
                                {
                                    type: 'line',
                                    fill: false,
                                    label: lastYear,
                                    backgroundColor: "rgb(75, 33, 42)",
                                    borderColor: "rgb(75, 33, 42, 0.2)",
                                    data:  [...dataAnualLastYear],
                                },
                            ],
                            options: {
                                responsive: true,
                                legend: {
                                display: false
                                },
                                animation: {
                                    duration: 0,
                                },
                            }
                        }}
                        type="bar"></Chart>
                </div>
            </div >
            <Logo img={LogoJalisco} width={65} height={65} />
            <p className="textFooterCharts">Copyright ® Gobierno de Jalisco 2021</p>
     </div >
    );
};

export default ChartsScreen;
