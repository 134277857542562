import React, {useState, useCallback} from "react";
import "./TablaSeguimientoArchivos.css";
import article from "../branding/assets/article.png";
import file_download_black from "../branding/assets/file_download_black.png"
import toggleOn from '../branding/assets/toggleBlack.svg';
import toggleOff from '../branding/assets/toggleOff.svg';
import Paginations from "./Pagination";

const TablaSeguimientoArchivos = (
    {   
        showMoreCharts,
        useWidth, 
        useHeight, 
        marginTop,
        showModalFile,
        setShowModalFile,
        showEstatusTable,
        showPublicacionesTable,
        setShowPublicacionesTable,
        setEstatusTable,
        showDatosGenerales, setShowDatosGenerales
        
    }) => {
        const MockFiles = [
            {
                "id": 1,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 2,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 3,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 3,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 3,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 3,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
        ]
        const headerDesktop= [
            "#Identificador", 
            "Fecha de cálculo",
            "Estatus",
            "Resultados"
        ]
    
        const headerGroupsMobileEstatus= [
            "#Identificador", 
            "Estatus",
            "Fecha de cálculo"
        ]
    
        const headerGroupsMobilePublicaciones= [
            "Usuario que subió", 
            "Resultados",
            "Publicar contenido"
        ]
    
        
        const MockStatusFiles = [
            {
                "id": 1,
                "idIdentificador": 15353638828,
                "fecha" : "20/23/2019",
                "status" : " No aceptado Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 2,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 3,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : " No Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 3,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 3,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "Aceptado",
                "resultados" :"",
                "archivo": String
            },
            {
                "id": 3,
                "idIdentificador": 123412646234,
                "fecha" : "20/23/2019",
                "status" : "No",
                "resultados" :"",
                "archivo": String
            },
        ]
    

        const [currentPage, setCurrentPage] = useState(1);
        let NUM_OF_RECORDS = MockFiles.length;
        let LIMIT = 5;

        const onPageChanged = useCallback(
            (event, page) => {
                console.log(page, 'page', event, 'event');
            event.preventDefault();
            setCurrentPage(page);
            },
            [setCurrentPage]
        );
        const currentData = MockStatusFiles.slice(
            (currentPage - 1) * LIMIT,
            (currentPage - 1) * LIMIT + LIMIT
        );


        const style = { showMoreCharts };
        const windowWidth = { useWidth };
        const clickStyles = { 
            width: style.showMoreCharts ? "30%" : "40%", 
            marginLeft: style.showMoreCharts ? "70%" : "60%", 
            height: useHeight, top:  marginTop
        }

        const showUploadModalFile = () =>{
            setShowModalFile(!showModalFile)
        }

        let headerMobile =   showEstatusTable ? headerGroupsMobileEstatus : headerGroupsMobilePublicaciones
        let tableSelect = useWidth > 400 ? headerDesktop : headerMobile 

    return (
        <div className="viewChartConponent" 
            style={windowWidth.useWidth > 800 
                ? clickStyles : { height: useHeight 
            }}
        >
        <div className="containerWithShadow">
            {useWidth > 800 && 
            <div className="headerFiles">
                <p className="titleFiles">Seguimiento de archivos</p>
                <button className="btnAddFile" onClick={showUploadModalFile}>Subir nuevo archivo</button>
            </div>} 
            {useWidth < 800 && showEstatusTable &&
            <div className="headerFiles">
                <button className="btnAddFileMin" style={{backgroundColor:"#708296"}} onClick={showUploadModalFile}>Subir archivo</button>
            </div>} 
             <table id="customers">
                <tr>
                    {tableSelect.map((task, i ) => {
                        return ( <th key={`task-${task}`}style={{textAlign:"center"}}>{task}</th>)
                    })}
                </tr>
                {useWidth > 800 &&
                    MockFiles.map((task, i ) => {
                        return(
                            <tr key={`task-${i}`} >
                                <td>{task.idIdentificador}</td>   
                                <td>{task.fecha}</td>
                                <td>
                                    <button className="btn">{
                                    task.status}
                                    </button>
                                    </td>
                                <td>{task.resultados}
                                {useWidth > 800 &&  
                                <>
                                    <img width="20px" alt="Archivo" 
                                        src={article} />
                                        <img width="20px" alt="Archivo" 
                                        src={file_download_black} />
                                </>
                                }   
                                </td>
                            </tr>   
                        )})
                }
                {useWidth < 800 && showEstatusTable && currentData &&
                    currentData.map((task, i ) => {
                        return(
                            <tr key={`task-${i}`} >
                                <td>{task.idIdentificador}</td>  
                                <td style={{display:"flex", textAlign:"center", justifyContent:"center"}}>
                                    <div style={{backgroundColor:"yellow", 
                                          borderRadius:"30px 30px 30px ", width:"10px", 
                                          height:"10px", marginTop:"10px", marginRight:"5px"
                                        }}
                                    />
                                    <p>{task.status}</p>
                                </td> 
                                <td>{task.fecha}</td>
                            </tr> 
                        )})
                    }
                    {useWidth < 800 && !showEstatusTable && showPublicacionesTable &&
                        currentData.map((task, i ) => {
                            return(
                                <tr key={`task-${i}`} >
                                    <td>{task.idIdentificador}</td> 
                                    <td>
                                        <img width="20px" alt="Archivo" src={article} />
                                        <img width="20px" alt="Archivo" src={file_download_black} />
                                    </td>
                                    <td>
                                        <img width="20px" 
                                            alt="Archivo" 
                                            src={task.status === "Aceptado" 
                                                ? toggleOn : toggleOff
                                            } 
                                        />
                                    </td>
                                </tr>   
                        )})
                    }
                </table>        
                <div style={{marginLeft:"70%"}}>
                    <Paginations
                        totalRecords={NUM_OF_RECORDS}
                        pageLimit={LIMIT}
                        pageNeighbours={2}
                        onPageChanged={onPageChanged}
                        currentPage={currentPage}
                    />
                </div>
        </div>
    </div>
    );
};

export default TablaSeguimientoArchivos;
