import React from 'react';
import Logo from '../branding/logo/Logo';
import LogoRioSantiago from '../branding/assets/logoRioSantiago.svg';
import {SignOut} from '../../api/Services';

import './ModalSubirArchivo.css';

let labelAlertSubmir = "Al presionar aceptar la sesión se cerrará automáticamente"

const OutModal = ({     
    setOut,
    out,
    uploadExito, 
    setUploadExito
}) =>{

    const logout = () =>  SignOut().then((response) => {
        if (response.status === 200) {
            setOut(!out)
            setUploadExito(!uploadExito)
    }else{
        setOut(!out)
    }
}).catch((e) => {
        setOut(!out)
        throw e;
    });

    const goBack = () => {
        setOut(!out)
    }

  return (
    <div className="modalContainer">
      <div className="boxModal">
        <div className="imgHeader">
          <Logo 
            img={LogoRioSantiago} 
            width={"55%"} 
            height={"55%"}
            style={{
              marginTop: "3%",
              marginleft: "5%"
            }}
          />
        </div>
          <h3 className="labelModal">CERRAR SESIÓN</h3>
          <p className="labelAlertSubmit">
            {labelAlertSubmir}
          </p>
          <div className="buttonContainer" >
            <div className="buttonOkModal"  
                style={{backgroundColor:"#ADADAD", marginLeft:"-5%"}} 
                onClick={goBack}
            > Regresar</div>
            <div className="buttonOkModal"  
                style={{backgroundColor:"#005FC5", marginLeft:"-5%", color:"#ffffff"}}  onClick={logout}
            > Aceptar</div>
          
        </div>
      </div>
    </div>
  )
}

export default OutModal