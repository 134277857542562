import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || '/api'

export const SignIn = async (user, pass) => {
    try {
        const body = {
            username : user,
            password: pass
        };
        let result = await axios.post(`${BASE_URL}/login`, body);
        return result
    } catch (e) {
        return e;
    }
};

export const SignOut = async () => {
    try {
        return await axios.post(`${BASE_URL}/login`);
    } catch (e) {
        return e;
    }
};

export const GetEstaciones = async () => {
    try {
        let result = await axios.get(`${BASE_URL}/public/last/ICA`);
        return result.data;
    } catch (e) {
        return {error: true};
    }
};

export const GetIcaPorAño = async () => {
    try {
        let result = await axios.get(`${BASE_URL}/public/ICA/average`);
        return result.data;
    } catch (e) {
        return {error: true};
    }
};


export const GetIcaPorMes = async () => {
    try {
        let result = await axios.get(`${BASE_URL}/public/ICA/average/monthly`);
        return result.data;
    } catch (e) {
        return {error: true};
    }
};


export const GetIcaPorEstacion = async (name) => {
    try {
        let result = await axios.get(`${BASE_URL}/public/ICA/average?station=${name}`);
        return result.data;
    } catch (e) {
        return {error: true};
    }
};

export const GetIcaPorEstacionPorAño = async (station, year) => {
    try {
        let result = await axios.get(`${BASE_URL}/public/ICA/average?station=${station}&year=${year}`);
        return result.data;
    } catch (e) {
        return {error: true};
    }
};

export const GetIcaPorEstacionPorMes = async (name, start, end) => {
    try {
        let result = await axios.get(`${BASE_URL}/public/ICA/average/monthly?station=${name}&start=${start}&end=${end}`);
        return result.data;
    } catch (e) {
        return {error: true};
    }
};

export const PostExcelFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    try {
        let result = await axios.post(`${BASE_URL}/excel/upload`,  
        formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          });
          console.log(result.data, 'result');
        return result.data;
    } catch (e) {
        return {error: e};
    }
};
