import React from 'react';

const HorizontalParagraph = ({title, paragraph}) =>{
    return (
        <div style={styles.divContainer}>
            <h1 style={styles.h1}>{title}</h1>
            <p style={styles.paragraph}>{paragraph}</p> 
        </div>
    )
}

const styles = {
    divContainer:{
        flex:1,
        justifyContent :"space-around",
        width:"auto",
        alignItems:"center",
        textAlign:"center",
        flexDirection:"row"
        
    },
    h1 :{
        color: "#3580CD",
        letterSpacing: "0px",
        justifyContent:"center",
        flexDirection:"row",
        width:"auto",
        textAlign: 'center',
        fontSize:'1.5rem',
        flex:"flex-wrap",
        fontFamily: "Nutmeg, normal",
      },
      paragraph:{
        color: "#6D6D6E",
        width:"auto",
        fontSize:'1em',
        flexDirection:"row",
        textAlign: 'center',
        fontFamily: "Nutmeg, normal",
        margin:"5%"
      },
}

export default HorizontalParagraph;