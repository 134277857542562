import React, { useState, useRef, useEffect } from 'react';

import Logo from '../branding/logo/Logo';
import LogoRioSantiago from '../branding/assets/logoRioSantiago.svg';
import file_download_black from '../branding/assets/file_download_black.png';
import {PostExcelFile} from '../../api/Services';

import './ModalSubirArchivo.css';

const useForceUpdate = () => useState()[1];
let labelAlertSubmir = "Al presionar aceptar el archivo será enviado para revisión automáticamente y podrá consultar su estatus desde su cuenta"

const ModalSubirArchivo = ({     
  showModalFile,
  setShowModalFile, 
  uploadExito, 
  setUploadExito
}) =>{

  const fileInput = useRef(null);
  const forceUpdate = useForceUpdate();
  
  const showUploadModalFile = () =>{
    setShowModalFile(!showModalFile)
  }
console.log(uploadExito, 'uploadExito');
  const Enviar = (fileInput) =>{
  console.log(fileInput, 'fileInput');
      PostExcelFile(fileInput).then(res => {
          // setDate(res.date)
          // setEstaciones(res.stations);
          setUploadExito(!uploadExito)
          setShowModalFile(!showModalFile)
          console.log(res, 'respuesta envista ');
      })
      .catch()
  

  }

  const fileNames = () => {
    const { current } = fileInput;
    if (current && current.files.length > 0) {
      let messages = [];
      for (let file of current.files) {
        messages = messages.concat(<p key={file.name}>{ file ? file.name : ""}</p>);
      }
      return messages;
    }
    return null;
  }

  return (
    <div className="modalContainer">
      <div className="boxModal">
        <div className="imgHeader">
          <Logo 
            img={LogoRioSantiago} 
            width={"55%"} 
            height={"55%"}
            style={{
              marginTop: "3%",
              marginleft: "5%"
            }}
          />
        </div>
          <h3 className="labelModal">Inserta tu archivo</h3>
          {fileNames()  &&
          <p className="labelAlertSubmit">
            {labelAlertSubmir}
          </p>}
          <div 
          className="buttonContainer" >
          <div className="buttonOkModal"  
            style={{backgroundColor:"#ADADAD", marginLeft:"-5%"}} 
            onClick={showUploadModalFile}>  Regresar  </div>
          <div className="buttonOkModal" style={{backgroundColor:"#FFFFFF"}}>
            {fileNames() 
              ? <p className="okButton" onClick={Enviar}>Aceptar</p>
              :  <label for="file-upload" class="custom-file-upload">
                    {fileNames() 
                      ? <i>{fileNames()}</i>
                      : <div style={{display:"flex"}}>
                          <p className="text">Subir archivo</p>
                          <img 
                            className="uploadButton"
                            src={file_download_black} 
                            fill="white"
                            alt="lista de Estaciones" 
                            width={"15%"} height={"15%"} 
                            style={{
                              marginLeft:"5%",
                              textColor: 'red',
                              transform: [{ rotate: '170deg'}]
                            }}
                          /> 
                        </div>
                    }
                  </label>
              }
           </div>
          <input
            id="file-upload" type="file"
            ref={fileInput}
            multiple
            onChange={forceUpdate}
            placeholder={fileNames()}
          />
        </div>
      </div>
    </div>
  )
}

export default ModalSubirArchivo;