import React from 'react';
import Logo from '../branding/logo/Logo';
import LogoRioSantiago from '../branding/assets/logoRioSantiago.svg';

import './ModalSubirArchivo.css';


const ResetPassword = ({  
    uploadExito, 
    setUploadExito,
    passwordReset, 
    setPasswordReset
}) =>{

    const goBack = () => {
      setPasswordReset(!passwordReset)
    }

  return (
    <div className="modalContainer">
      <div className="boxModal">
        <div className="imgHeader">
          <Logo 
            img={LogoRioSantiago} 
            width={"55%"} 
            height={"55%"}
            style={{
              marginTop: "3%",
              marginleft: "5%"
            }}
          />
        </div>
          <h3 className="labelModal">¿Olvidaste tu contraseña?</h3>
          <form action="">
              <input 
                type="text" 
                class="input-text" 
                placeholder="Ingresa correo electrónico" 
                required
                /> 
          </form>
          <div className="buttonContainer" >
            <div className="buttonOkModal"  
                style={{backgroundColor:"#ADADAD", marginLeft:"-5%"}} 
                onClick={goBack}
            > Regresar</div>
            <div className="buttonOkModal"  
                style={{backgroundColor:"#005FC5", marginLeft:"-5%", color:"#ffffff"}}
            > Aceptar</div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;