import React from 'react';
import { Line, Bar, Pie, PolarArea, Radar } from '@reactchartjs/react-chart.js'

const styles = {
    card: {
        background: "white",
        boxShadow: "0 .125rem .5rem #00000029",
        borderRadius: "2.25rem",
        padding: "1rem",
        margin: ".3rem",
        opacity: "1"
    }
}

const ChartComponent = props => {
    const radarOptions = {
        scale: {
            ticks: {  
                min: 0,
                max: 100,
                stepSize: 10
            },
        },
    }

    const generalOptions = {
        scales: {
            yAxes: [
                {
                    ticks: {  
                        min: 0,
                        max: 100,
                        stepSize: 20
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'ICA'
                    }
                }
            ],
        }
    }

    const multiLineOptions = {
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    gridLines: {
                        drawOnArea: false,
                    },
                },
            ],
        },
    }

    if (props.type === "line" || props.type === "multiline")
        return < Line data={props.data} options={props.type === "line" ? generalOptions : multiLineOptions} />;

    if (props.type === "pie")
        return < Pie data={props.data} />;

    if (props.type === "polar")
        return < PolarArea data={props.data} />;

    if (props.type === "radar")
        return < Radar data={props.data} options={radarOptions} />;

    return < Bar data={props.data} options={generalOptions} />;
}

const Chart = (props) => (
    <>
        <div style={styles.card}>
            {ChartComponent(props)}
        </div>
    </>
)

export default Chart;