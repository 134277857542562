import React, { useState } from 'react';

import ListaEstrategia from '../estrategia/ListaEstrategia';

import cicloCalidad from '../branding/assets/cicloCalidad.png';
import cicloEstrategia from '../branding/assets/cicloEstrategia.png';

import './ListaEstaciones.css';

const ListaEstaciones = (
    { 
    useWidth,
    label, 
    hiddenElement, 
    estaciones, 
    clickId, 
    setClickId, 
    click, 
    setClick, 
    date,
    showEstrategia, 
    setEstrategia,
    showListEstaciones, 
    setListEstaciones,
    toggleLayer,
    privateView,
    showLayer,
    }) => {

    // console.log('toggleLayer', toggleLayer);
    const styleClickItem = {
        "color": "#002445",
        "backgroundColor": "#FFFFFF",
        "textColor": "FFFFFF"
    }

    const estacionesList = [...estaciones]?.map((task, i ) => {
        i ++;
        let ica = Math.round(parseInt(task.ica));
        let icaConDecimales = Number.parseFloat(task.ica).toFixed(1);

        let Excelente = ica > 100  
            ? {label:  "Excelente", color:"#2A7BEA"} 
            : {label: "Buena", color:"#2BE063"};

        let buenaCalidad =  ica > 70  
            ? Excelente 
            : {label: "Media", color:"#FAD14A"};

        let malaCalidad = ica <=25  
            ? {label:"Muy mala", color:"#F41C1C"} 
            : {label:"Mala", color:"#FF9439"}

        const condicion = ica <= 50 ? malaCalidad : buenaCalidad;

        return(
            <div key={`task_${task?.id}`}
                style={{ backgroundColor: clickId === task.id ? styleClickItem.backgroundColor : "#BEE6F4" }}
                className="itemContainer"
                onClick={() => {
                    if (click === task.id) {
                        setClickId(``);
                        return;
                    }
                    setClickId(task.id);
                    setClick(true);
                }}>
                <div className="item" >
                    <p className="porcentaje" style={{ backgroundColor: clickId === task.id ? styleClickItem.backgroundColor : "#BEE6F4", color: clickId === task.id ? styleClickItem.color : "#206AAD" }}>{icaConDecimales}</p>
                    <div className="textContainer">
                        <p className="municipioText">{task.description}</p>
                        <div className="condicionContainer"
                            style={{
                                fontSize: "smaller",
                                backgroundColor: condicion.color, marginRight: 8,
                                width: click === task.id ? 65 : 9, 
                                height: 10, borderRadius: 50, 
                                marginTop: "5px",
                                color: clickId === task.id 
                                    ? styleClickItem.color 
                                    : condicion.color,
                            }}>
                            {clickId === task.id ?
                                <p className="calidadText" 
                                    style={{ backgroundColor: condicion.color }}>{condicion.label }</p> :
                                <>
                                    <p style={{
                                        fontSize: "smaller",
                                        color: clickId === task.id 
                                            ? styleClickItem.textColor 
                                            : condicion.color,
                                        paddingLeft: clickId === task.id 
                                            ? "15px" 
                                            : "13px", 
                                        zIndex: clickId === task.id ? 700 : 0,
                                        paddingRight: "13%"
                                    }}>{task.condicion}</p>
                                    <p style={{
                                        fontSize: "smaller",
                                        color: clickId === task.id 
                                            ? styleClickItem.textColor 
                                            : condicion.color,
                                        zIndex: 700,
                                    }}>{condicion.label}</p>
                                </>}
                        </div>
                    </div>
                </div>
            </div>
        )
    });

    const switchMenuState = () => {
        setEstrategia(!showEstrategia); 
        setListEstaciones(!showListEstaciones)
    }

    return (
        <div >
            { useWidth > 800 && 
                <>
                    { !hiddenElement ? 
                            <div className="titleContainerList" style={ 
                                showListEstaciones 
                                    ? { height: "290px", transition: "background-color 1s ease 0s", zIndex: 700,
                                    position: "absolute",  top: showEstrategia ? "50px" :"120px"  }
                                    : { height: "190px", transition: "background-color 1s ease 0s", top: !showEstrategia ? "30%" :"70px"  }
                              }
                            >
                            <div className="divTitleBlue"
                                onClick={switchMenuState}  
                                style={
                                    showEstrategia
                                    ? {backgroundColor: '#005FC5', top:"105px"}
                                    : {backgroundColor: '',  
                                    top: showEstrategia ? "135px" : "135px"}
                                }
                            >
                                <img width="25px" height="25px"
                                    style={{marginRight:"15px", marginLeft:"10px",
                                     paddingTop: "8px"
                                    }}
                                    src={ cicloEstrategia} />
                                <p>Estrategia</p>
                            </div>
                            <div className="divTitleBlue" 
                                onClick={switchMenuState} 
                                style={
                                    showListEstaciones
                                    ? {backgroundColor: '#005FC5', top:"190px"}
                                    : {backgroundColor: '',  top:"164px"}
                                }
                            >
                                <img width="25px" height="25px"
                                    style={{marginRight:"15px", marginLeft:"10px", paddingTop: "8px"}}
                                    src={ cicloCalidad} />
                                <p>{label}</p>
                            </div>
                            {showListEstaciones &&
                                <div style={
                                    {marginTop: showEstrategia ? '180px': ""}, 
                                    {marginBottom: showEstrategia ? "-60px" : ""}
                                   }>
                                    <p className="boldText">Estaciones de medición</p>
                                    <div style={{display:'flex', paddingInline:"10%"}}>
                                        <p className="lightText">Datos al día</p>
                                        <p className="mediumText">{date.split(" ")[0].split("-").reverse().join("/")}</p>
                                    </div>
                                </div>
                            }
                        </div> : <div style={{height: privateView ? "300px": "90px", backgroundColor:"#002445"}}>
                            <p className="divTitleBluePrivate">{label}</p>
                        </div>
                    }
                </>
            }
            { !showEstrategia &&  <>
            {useWidth > 800 &&  <div style={{backgroundColor:"#002F5D", height:"80%", paddingBottom:"30%",  top: "330px",}}>
            </div>}
               <div 
                    className="listContainer" 
                    style={{ 
                        top:  useWidth > 800 ? "330px" : "",
                        height: useWidth > 800 
                            ? "60vh"
                            : "75vh", 
                        paddingBottom:"15%" 
                    }}
                >
                    {estacionesList}
                </div>
                </>
            }
            {/* {showEstrategia && useWidth > 800 && 
                <div 
                    className="listContainer" 
                    style={{ 
                        height: useWidth > 420 
                        ? "50vh"
                        : "100vh", 
                    paddingBottom:"20%",
                    backgroundColor:"#ECEDEF",
                }}
                >
                    <ListaEstrategia
                            toggleLayer={toggleLayer}
                            showLayer={showLayer}
                    />
                </div>
            } */}
        </div>
    )
}

export default ListaEstaciones;