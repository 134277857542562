import React, { useState, useEffect } from 'react';
import ChartsScreen from '../../components/chart/ChartsScreen';
import NavbarComponent from '../../components/nabvar/NabvarComponent';
import ListaEstaciones from '../../components/estaciones/ListaEstaciones';
import ListaEstrategia from '../../components/estrategia/ListaEstrategia';

import MapView from '../../components/mapview/MapView';
import menu from '../../components/branding/assets/menu.svg';
import LeftArrow from '../../components/branding/assets/leftArrow.svg';
import arrowRightCircle from '../../components/branding/assets/arrowRightCircle.svg';

import * as layers from '../../data/capas';

import {
    GetEstaciones, 
    GetIcaPorAño, 
    GetIcaPorEstacion, 
    GetIcaPorMes, 
    GetIcaPorEstacionPorAño, 
    GetIcaPorEstacionPorMes
} from "../../api/Services";
import cicloCalidad from '../../components/branding/assets/cicloCalidad.png';
import cicloEstrategia from '../../components/branding/assets/cicloEstrategia.png';
import './HomeScreen.css';

const HomeScreen = () => {
    const [estaciones, setEstaciones] = useState("");
    const [useHeight, setHeight] = useState(window.innerHeight);
    const [useWidth, setWidth] = useState(window.innerWidth);
    const [click, setClick] = useState(true)
    const [showMoreCharts, setShowMoreCharts] = useState(true)
    const [clickId, setClickId] = useState('')
    const [date, setDate] = useState('');
    const style = { showMoreCharts };
    const [icaPorAño, setIcaPorAño] = useState('');
    const [icaPorEstacion, setIcaPorEstacion] = useState('');
    const [icaPorMes, setIcaPorMes] = useState('');
    const [icaName, setIcaName] = useState('');
    const [icaDateEstacion, setIcaDateEstacion]  = useState('');
    const [icaDateEstacionPen, setIcaDateEstacionPen]  = useState('');
    const [showEstrategia, setEstrategia] = useState(false);
    const [showListEstaciones, setListEstaciones] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [icaEstacionesUltimoAno, setIcaEstacionUltimoAno] = useState('');
    const [addPadding, setPadding] = useState(false);
    const lastYear = new Date().getFullYear();

    var script = document.createElement('script');
    script.async = false;
    script.src = '/resources/qgis2web.js' ;

    const  initMapEstrategia = () =>{
        document.body.appendChild(script);
    }

    useEffect(()=>{
        if(showEstrategia){
            setTimeout( function() { initMapEstrategia()}, 200);   
        }
    }, [showEstrategia])

    let estacion = {}
    let name="";
    let icaPorEstacionDelUltimoAño = "";

    for (var i = 0; i < estaciones?.length; i++) {
        if (estaciones[i].id === clickId) {
            estacion = estaciones[i]
            name = estaciones[i].name
            icaPorEstacionDelUltimoAño  = estaciones[i].ica
            break;
        }
    }
    useEffect(() => {
        // initMapEstrategia();
        GetEstaciones().then(res => {
            setDate(res.date)
            setEstaciones(res.stations);
        })
        .catch()

        GetIcaPorMes().then(res => {
            setIcaPorMes(res);
        })
        .catch()
    }, []);

    useEffect(() =>{
        initMapEstrategia()
    })
    useEffect(()=>{
        const arr_estaciones = Array.from(estaciones);
        const tieneEstaciones = arr_estaciones?.length;
        const year = new Date().getFullYear();
        if (tieneEstaciones) {
            Promise.all(
                arr_estaciones
                    .filter(v => !!v.name)
                    .map(val => GetIcaPorEstacionPorAño(val.name, year - 1))
            ).then(res => {
                const icas = res.map(estacion => {
                    // console.log(estacion[0]);
                    const valor = estacion.pop()?.ica;
                    return valor;
                });
                setIcaName(icas)
            })    
            
            Promise.all(
                arr_estaciones
                    .filter(v => !!v.name)
                    .map(val => GetIcaPorEstacionPorAño(val.name, year))
            ).then(res => {
                const icas = res.map(estacion => {
                    // console.log(estacion[0]);
                    const valor = estacion.pop()?.ica;
                    return valor;
                });
                setIcaEstacionUltimoAno(icas)
            })  
        }
    }, [estaciones]);

    useEffect(() => {
        if(clickId && name){
            GetIcaPorEstacion(name).then(res => {
                setIcaPorEstacion(res);
            })
            .catch( (e)=>{console.log(e, 'error');})

            var e = new Date(lastYear+"/01/01")
            let count = 9;
            let mesesEstacion = [
                {
                    start :  e.getFullYear() +"/"+ (e.getMonth()+1) +"/"+ e.getDate(), 
                    end:  e.getFullYear() +"/"+ (e.getMonth()+2) +"/"+ (e.getDate()+1)
                }
            ];
        
            for (var i = 0; i <= count; i++) {
                e.setMonth(e.getMonth() + 1)
                mesesEstacion.push( {
                    start :  e.getFullYear() +"/"+ (e.getMonth()+1) +"/"+ e.getDate(), 
                    end:  e.getFullYear() +"/"+ (e.getMonth()+2) +"/"+ (e.getDate()+1)
                });
            }
            mesesEstacion.push({
                start :  e.getFullYear() +"/12/01", 
                end:  (e.getFullYear() + 1) +"/01/02"            
            });
            let ica = []
            Promise.all(
                [...mesesEstacion]
                    .map(val =>  GetIcaPorEstacionPorMes(name, val.start, val.end))
            ).then(res => {
                [...res]?.map(function(estacion, i){
                    ica.push(estacion[estacion.length-1]?.ica);
                });
                setIcaDateEstacion(ica)
            }) 
            let ePast = new Date(lastYear - 1 + "/01/01")
            mesesEstacion = [
                {
                    start :  ePast.getFullYear() +"/"+ (ePast.getMonth()+1) +"/"+ ePast.getDate(), 
                    end:  ePast.getFullYear() +"/"+ (ePast.getMonth()+2) +"/"+ (ePast.getDate()+1)
                }
            ];
        
            for (var i = 0; i <= count; i++) {
                ePast.setMonth(ePast.getMonth() + 1)
                mesesEstacion.push( {
                    start :  ePast.getFullYear() +"/"+ (ePast.getMonth()+1) +"/"+ ePast.getDate(), 
                    end:  ePast.getFullYear() +"/"+ (ePast.getMonth()+2) +"/"+ (ePast.getDate()+1)
                });
            }
            mesesEstacion.push({
                start :  ePast.getFullYear() +"/12/01", 
                end:  (ePast.getFullYear() + 1) +"/01/02"            
            });    
            let icaPast = []
            Promise.all(
                [...mesesEstacion]
                    .map(val =>  GetIcaPorEstacionPorMes(name, val.start, val.end))
            ).then(res => {
                [...res]?.map(function(estacion, i){
                    icaPast.push(estacion[estacion.length-1]?.ica);
                });
                setIcaDateEstacionPen(icaPast);
            }) 
        }
        
        GetIcaPorAño()
        .then(res => {
            setIcaPorAño(res);
        })
        .catch( (e)=>{console.log(e, 'error');})

        const handleResize = () => {
            setHeight(window.innerHeight);
            setWidth(window.innerWidth);
        };
      
        window.addEventListener("resize", handleResize);
        return () => { window.removeEventListener("resize", handleResize); };

    }, [clickId]);

    const showMore = () => { setShowMoreCharts(!showMoreCharts); }
    const goBack = () => { setClickId("") }
    

    const arrIcaPorAño = [...icaPorAño]?.map(function(val, i){
        return val.year;
    });

    const arrIcaPorMes = [...icaPorMes]?.map(function(val, i){
        return val;
    });

    let antepenultimoAñoValue = [...icaPorAño]?.filter((item) => { 
        return item.year === lastYear -1;
    })

    let icaConMesesDelUltimoAño = [...arrIcaPorMes]?.filter((item) => {
        return item.year === lastYear;
    });

    let icaConMesesDelAntepenultimoAño = [...arrIcaPorMes]?.filter((item) => {
        return item.year === lastYear -1;
    });

    const [showLayer, setLayer] = useState(Object.keys(layers).reduce((a, key) => {
        a[key] = false;
        return a;
    }, {}));

    const toggleLayer = (layerName, state = null) => {
        let newState = {...showLayer};

        newState[layerName] = state !== null
            ? state
            : !newState[layerName];

        setLayer(newState);
    }
    const touchChartsComponent = (evt) =>{
        setPadding(evt.target.scrollTop !== 0);
        setShowModal(false)
    }

    return (
        <div className="screen" style={{ height: useHeight }} >
            <div className="container" style={{
                zIndex: 800}} >
                <NavbarComponent 
                    useWidth={useWidth} click={click} setClick={setClick} showEstrategia={showEstrategia}
                    setEstrategia={setEstrategia}
                    showModal={showModal}
                    showListEstaciones={showListEstaciones}
                    setListEstaciones={setListEstaciones}
                    setShowModal={setShowModal}
                    privateView={false}
                />
            </div>
            <div className="bodyScreen" style={{position:'relative'}}>
                <div className="cardMap">
                    {useWidth < 800 && !click &&
                        <ListaEstaciones 
                            useWidth={useWidth} 
                            useHeight={useHeight} 
                            estaciones={estaciones} 
                            click={click} 
                            setClick={setClick} 
                            clickId={clickId} 
                            setClickId={setClickId} 
                            date={date}
                            label={"Calidad del agua"}
                            showEstrategia={showEstrategia}
                            setEstrategia={setEstrategia}
                            showListEstaciones={showListEstaciones} 
                            setListEstaciones={setListEstaciones}
                            toggleLayer={toggleLayer}
                            showLayer={showLayer}
                        />
                    }
                    {useWidth < 800 && showEstrategia && 
                        <ListaEstrategia
                            useWidth={useWidth} 
                            useHeight={useHeight} 
                            estaciones={estaciones} 
                            click={click} 
                            setClick={setClick} 
                            clickId={clickId} 
                            setClickId={setClickId} 
                            date={date}
                            label={"Calidad del agua"}
                            showEstrategia={showEstrategia}
                            setEstrategia={setEstrategia}
                            showListEstaciones={showListEstaciones} 
                            setListEstaciones={setListEstaciones}
                            toggleLayer={toggleLayer}
                            showLayer={showLayer}
                        />
                    }
                     {useWidth > 810 && click && 
                            <ListaEstaciones 
                                useWidth={useWidth} 
                                useHeight={useHeight} 
                                estaciones={estaciones} 
                                click={click} 
                                setClick={setClick} 
                                clickId={clickId} 
                                setClickId={setClickId} 
                                date={date}
                                label={"Calidad del agua"} 
                                showEstrategia={showEstrategia}
                                setEstrategia={setEstrategia}
                                showListEstaciones={showListEstaciones} 
                                setListEstaciones={setListEstaciones}
                                toggleLayer={toggleLayer}
                                showLayer={showLayer}
                            />
                    } 
                     
                    {click && 
                        <div className="mapCont" style={{width: showEstrategia? '100vw' : '100vw'}}>
                            {!showEstrategia && 
                            <div className="mapContainerL" style={{width:'100%'}} onClick={touchChartsComponent} >
                                <MapView
                                    showLayer={showLayer}
                                    estacion={estacion}
                                    click={clickId}
                                    estaciones={estaciones} 
                                    privateView={false}
                                    setClick={setClick}
                                    setClickId={setClickId}>
                                </MapView> 
                            </div>}
                            {showEstrategia &&
                            <div id="map" className="mapContainer"  
                                style={{width: showEstrategia ? 'calc(100% - 0px)' : 'calc(100% - 270px)', 
                                    marginLeft: !showEstrategia ? " 270px !important" : "", height:'100vh', 
                                    display:showEstrategia ? 'block' : 'none'}}
                            >
                                <div id="popup" className="ol-popup">
                                    <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                                    <div id="popup-content"></div>
                                </div>
                            </div>}
                    </div>}
                </div>
                {useWidth > 800 && !clickId && !showEstrategia &&
                    <img width="25px" 
                        alt="Mostrar gráficas" 
                        className="showMore" 
                        onClick={showMore} 
                        style={style.showMoreCharts  ? { marginLeft: "19%" } : { marginLeft: "9%" }}
                        src={showMoreCharts ? menu : arrowRightCircle} />}
                {useWidth > 801 && clickId !== "" && !showEstrategia &&
                    <div onClick={goBack}>
                        <img width="25px" alt="Mostrar gráficas" className="goBack"
                            style={style.showMoreCharts ? { marginLeft: "19%" } : { marginLeft: "9%" }}
                            src={LeftArrow} />
                        <p style={style.showMoreCharts ? { marginLeft: "71%" } : { marginLeft: "63%" }} className="goBackText">Regresar</p>
                    </div>}
                {click && !showEstrategia && !showModal &&
                    <div className="chartDataWrapper" onScroll={touchChartsComponent}  onClick={touchChartsComponent}
                        style={{
                            marginTop: addPadding ? '' : 'calc(' + (100 * (useHeight / useWidth) - 40) + '% - 50px)',
                            paddingTop: addPadding ? 'calc(' + (100 * (useHeight / useWidth) - 40) + '% - 50px)'  :'',
                        }}>
                        <ChartsScreen
                            goBack={goBack}
                            showEstrategia={showEstrategia}
                            icaValueChart={icaPorAño}
                            icaPorEstacionDelUltimoAño={icaPorEstacionDelUltimoAño}
                            icaPorEstacion={icaPorEstacion}
                            icaConMesesDelUltimoAño={icaConMesesDelUltimoAño}
                            icaEstacionesUltimoAno={icaEstacionesUltimoAno}
                            icaConMesesDelAntepenultimoAño={icaConMesesDelAntepenultimoAño}
                            icaPorMesEstacionUltimoAno={icaDateEstacion}
                            estacionPorNombreIcaAño={icaName}
                            estaciones={estaciones} 
                            useHeight={useHeight} 
                            useWidth={useWidth} 
                            showMoreCharts={showMoreCharts} 
                            setShowMoreCharts={setShowMoreCharts} 
                            estacion={estacion} 
                            click={clickId}
                            date={date}
                            marginTop={"0"}
                            antepenultimoAñoValue={antepenultimoAñoValue}
                            icaPorMesEstacionPenUltimoAno={icaDateEstacionPen}
                            />
                    </div>
                }
            </div>
        </div>
    )
}

export default HomeScreen;
