import React, { useState } from 'react';
import Logo from '../branding/logo/Logo';
import LogoRioSantiago from '../branding/assets/logoRioSantiago.svg';
import LogoJalisco from '../branding/assets/logoJalisco.svg';import LogoRioSantiagoBlancoAzul from '../branding/assets/logoRioSantiagoBlancoAzul.svg';
import cicloCalidad from '../branding/assets/cicloCalidad.png';
import cicloEstrategia from '../branding/assets/cicloEstrategia.png';
import deleteIcon from '../branding/assets/delete.svg';
import menuPoint from '../branding/assets/menuPoint.png';
import Arrow from '../branding/assets/arrow.svg';

import './NabvarComponent.css';

const NavbarComponentPrivate = ({
  useWidth,
  click, 
  setClick,  
  showEstrategia, 
  setEstrategia, 
  showModal, 
  setShowModal,
  showPublicacionesTable,
  setShowPublicacionesTable,
  showEstatusTable,
  setEstatusTable,
  showDatosGenerales, setShowDatosGenerales,
  showModalOut, setShowModalOut
}) => {

  const style = { click };

  const changeShowListOrGraphics = () =>{
    if(showDatosGenerales && showEstatusTable){
      setClick(false);
    }
   
    if(!showDatosGenerales && showPublicacionesTable){
      setClick(true);
      setEstrategia(!showEstrategia)
    }

    if(!showDatosGenerales && !showPublicacionesTable && showEstatusTable){
      setClick(true);
      setEstrategia(!showEstrategia)
      setEstatusTable(!showEstatusTable)
    }

    if( showEstatusTable && !showDatosGenerales){
      setEstrategia(!showEstrategia)
      setShowPublicacionesTable(!showPublicacionesTable)
    }

    if( !showEstatusTable && showDatosGenerales){
      setEstrategia(!showEstrategia)
      setEstatusTable(!showEstatusTable)
    }

    if(!showEstatusTable && showPublicacionesTable && showDatosGenerales){
      setEstatusTable(!showEstatusTable)
      setShowPublicacionesTable(!showPublicacionesTable)
    }
   
  }
  const changeShowTable = () =>{
    console.log('table');
    if(!showEstatusTable && showDatosGenerales ){
      console.log('!showEstatusTable && showDatosGenerales');
      setClick(!click);
      setEstatusTable(true)
    }
    if(showEstatusTable && !showDatosGenerales){
      console.log('showEstatusTable && !showDatosGenerales');
      setEstrategia(!showEstrategia)
      // setEstatusTable(!showEstatusTable)
    }
    if(!showPublicacionesTable && !showDatosGenerales){
      console.log('!showPublicacionesTable && !showDatosGenerales');
      setClick(true);
    }

  }

  const changeColor = () => {
    setClick(!click);
    setShowModal(false);
    setEstrategia(false);
  }

  const shitchShowEstrategia = () => {
    setEstrategia(!showEstrategia);
    setShowModal(false);
    setClick(true);
  }

  const switchShowModal = () => {
    setShowModal(!showModal);
  }

  const showMoreOptions = () =>{
    if(showEstatusTable){ setClick(true) }
    setShowDatosGenerales(!showDatosGenerales)
  }
  const switchShowModalOutSession = () =>{
    setShowModalOut(!showModalOut);
  }

  return (
    <>
     {useWidth < 800 &&
      <div style={{backgroundColor:"#8ACCE2"}} >
        {showModal &&
          <div style={{backgroundColor:"#002445", height: "280px", borderRadius: "10px "}}>
            <div style={{
              justifyContent: "space-between",
              paddingTop:"20px",
              marginInline: "20px",
              display: "flex"
              }}
            >
              <Logo img={style.click 
                ? LogoRioSantiago 
                : LogoRioSantiagoBlancoAzul
                } 
                width={"35%"} 
                height={"25%"}  
                style={{
                  marginTop: "3%",
                  marginleft: "5%"
                }}
              />
              <img 
                className="closeButton"
                src={deleteIcon} 
                fill="white"
                alt="lista de Estaciones" 
                width={"7%"} height={"7%"} 
                style={{
                  textColor: 'red'
                }}
                onClick={switchShowModal}
              />
            </div> 
          <div 
            className="divTitleBlue"
            onClick={shitchShowEstrategia}
            style={{backgroundColor: '#005FC5', marginTop:"40px"}}
          >
            <img 
              width="25px" height="25px"
              style={{marginRight:"15px", marginLeft:"10px", paddingTop: "8px"}}
              src={ cicloEstrategia}
            />
            <p>Datos generales</p>       
          </div>
          <div 
            className="divTitleBlue" 
            style={{
              backgroundColor: '#005FC5', 
              marginTop:"100px"
            }}
            onClick={changeColor}
          >
            <img 
              width="25px" 
              height="25px"
              style={{
                marginRight:"15px", 
                marginLeft:"10px", 
                paddingTop: "8px"
              }}
              src={cicloCalidad}  
            />
            <p>Estaciones de medición</p>
          </div>
        </div>
      }
      {!showModal &&         
        <div>
          <div className="navSmallScreen" style={{backgroundColor:"#8ACCE2",}}>
            <div>
              <Logo img={style.click ? LogoRioSantiago : LogoRioSantiagoBlancoAzul} width={"50%"} height={"100%"} />
            </div>
            <div className="fullNavSmallScreen">
              <Logo 
                img={LogoJalisco} 
                width={40} 
                height={40} 
              />
              <img 
                src={menuPoint}
                width={"25%"} 
                height={"90%"} 
                style={{
                  paddingBottom:"3%"
                }}
                onClick={switchShowModal}
              />
            </div>   
          </div>
        </div>
      }
        <div className="containerselector">
          {/* <div className="scrollSelector"> */}
          <div className="munuOptions"  
          
          onClick={showEstatusTable && !showPublicacionesTable
              ? changeShowTable 
              : changeShowListOrGraphics }>
            {!showDatosGenerales && 
              <img src={Arrow} alt="lista de Estaciones" width={"20%"} height={"55%"} 
                className="downChevron"
                onClick={showMoreOptions}
              />
            }
            <p>{ showDatosGenerales ? "Datos generales" : "Seguimiento de archivos"}</p>
          </div>
          <div className="munuOptions"  
            onClick={!showEstatusTable && showDatosGenerales 
              ? changeShowTable 
              : changeShowListOrGraphics 
              }
          >
          <p>{ !showDatosGenerales  ?  "Publicaciones" : "Estaciones de medición"}</p>   
          {showDatosGenerales && 
            <img src={Arrow} 
              alt="lista de Estaciones" 
              width={"20%"} 
              height={"55%"} 
              className="downChevron"
              onClick={showMoreOptions}
            />
          }
          </div>
        </div>
    </div> 
    } 
    { useWidth > 450 && 
      <div style={styles.container}>
        <div style={styles.box}>
          <Logo img={LogoRioSantiago} width={150} height={60}/>
        </div>
        <div  className="modalOut" style={{  marginLeft:"10%",
      marginRight:"2%", display:"flex"}}>
      
        <img 
                src={menuPoint}
                width={"98%"} 
                height={"90%"} 
                style={{marginTop:"5%",
                  marginLeft:"30%"
                  // paddingBottom:"3%"
                }}
                onClick={switchShowModalOutSession}
              />

        </div>
        
          
      </div>
    
    }
      
    </>
  )
}

const styles = {

  logoJalisco: {
    with: "10%",
    marginInline: "44.5%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1%"
  },
  logoJaliscoDesktop: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2%"
  },
  container: {
    flex: 1,
    backgroundColor: "#002445",
    display: "flex",
    justifyContent:"space-between",
    height: "72px",
  },
  box: {
    height: "60px",
    paddingTop: "10px",
    backgroundColor: "#8ACCE2",
    paddingInline: "60px"
  }

};

export default NavbarComponentPrivate;