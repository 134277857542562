import React, { useState } from 'react';
import '../estaciones/ListaEstaciones.css';

const ListaEstrategia = ({toggleLayer, showLayer}) =>{

    const groups = {
        SIOP: [
            'SIOPAccionesLineales',
            'SIOPAccionesPuntuales',
            'SIOPAccionesPolgonos',
        ],
        SGIA: [
            'PTAR',
            'ControldeMalezalineal',
            'ControldeMaleza',
            'Colectores',
        ],
        SADER: [
            'Rastros',
            'PID',
            'LaboratorioOrganismo',
            'CAT',
            'Porcicola',
        ],
        SEMADET: [
            'Sitiosdedisposicinfinal',
            'CierreAbandonoSanIgnacio',
            'PCAVCertificadasFeb2020',
            'PCAVRecertificadasFeb2020',
        ],
    }

    const [groupState, setGroup] = useState(
        Object.keys(groups).reduce((a, key) => {
            a[key] = false;
            return a;
        }, {})
    )

    const toggleGroup = (groupName) => {
        const newState = {...groupState};
        const state = !newState[groupName]; 
        newState[groupName] = state; 

        groups[groupName]?.map(key => {
            toggleLayer(key, state);
        }); 

        setGroup(newState);
    }

    return(
        <div></div>
        // <div className="listContainerEstrategia" style={{ display:'none', height: "60vh", paddingBottom:"15%", backgroundColor:"#ECEDEF", }}>
        //    <div  style={{ 
        //     paddingInlineStart:"20px" }}>
        //         <div style={{display:"flex", marginBottom: "-20px"}}>
        //             <input type="checkbox" style={{ marginTop:"22px"}} onChange={toggleGroup?.bind(this, 'SIOP')} checked={groupState['SIOP']} />
        //             <p style={{color:"#002445", fontWeight: "800"}}>SIOP</p>
        //         </div>
        //         <div>
        //             <div style={{display:"flex"}}>
        //                 <input type="checkbox" style={{ marginTop:"22px",  marginLeft:"20px"}}  onChange={toggleLayer?.bind(this, 'SIOPAccionesLineales', null)} checked={showLayer?.['SIOPAccionesLineales']}/>
        //                 <p style={{color:"#50667B"}}>SIOP Acciones Lineales</p>
        //             </div>
        //             <p style={{color:"#6D737C", textAlign:"start", marginLeft:"60px",marginTop: "-13px"}}>2019</p>
        //             <p style={{color:"#6D737C", textAlign:"start", marginLeft:"60px", marginTop: "-13px"}}>2020</p>
        //             <p style={{color:"#6D737C", textAlign:"start", marginLeft:"60px", marginTop: "-13px"}}>2021</p>
        //         </div>
        //         <div>
        //             <div style={{display:"flex",  marginTop:"-22px", }}>
        //                 <input type="checkbox" style={{ marginTop:"22px",  marginLeft:"20px"}}   onChange={toggleLayer?.bind(this, 'SIOPAccionesPuntuales', null)} checked={showLayer?.['SIOPAccionesPuntuales']} />
        //                 <p style={{color:"#50667B"}}>SIOP Acciones Puntuales</p>
        //             </div>
        //             <div style={{display:"flex", height:"10px", marginLeft:"40px", marginTop:"-5px"}}>
        //                 <div style={{borderRadius:"30px 30px 30px ", width:"10px", 
        //                 height:"10px",marginRight:"10px", backgroundColor:"#F5F5F5"}}></div>
        //                 <p style={{color:"#6D737C", textAlign:"start"}}>2019</p>
        //             </div>
        //             <div style={{display:"flex", height:"10px", marginLeft:"40px", marginTop:"10px"}}>
        //                 <div style={{borderRadius:"30px 30px 30px ", width:"10px", 
        //                 height:"10px",marginRight:"10px", backgroundColor:"#C9CAE3"}}></div>
        //                 <p style={{color:"#6D737C", textAlign:"start"}}>2020</p>
        //             </div>
        //             <div style={{display:"flex", height:"10px", marginLeft:"40px", marginTop:"10px"}}>
        //                 <div style={{borderRadius:"30px 30px 30px ", width:"10px", 
        //                 height:"10px",marginRight:"10px", backgroundColor:"#9791AC"}}></div>
        //                 <p style={{color:"#6D737C", textAlign:"start"}}>2021</p>
        //             </div>
        //         </div>
        //         <div>
        //             <div style={{display:"flex",  marginTop:"10px", marginBottom:"-10px" }}>
        //                 <input type="checkbox" style={{ marginTop:"22px",  marginLeft:"20px"}}  onChange={toggleLayer?.bind(this, 'SIOPAccionesPolgonos', null)} checked={showLayer?.['SIOPAccionesPolgonos']} />
        //                 <p style={{color:"#50667B"}}>SIOP Acciones Polígonos</p>
        //             </div>
        //             <div style={{display:"flex", height:"10px", marginLeft:"40px", marginTop:"10px"}}>
        //                 <div style={{ width:"10px", 
        //                 height:"10px",marginRight:"10px", backgroundColor:"#F5F5F5"}}></div>
        //                 <p style={{color:"#6D737C", textAlign:"start"}}>2019</p>
        //             </div>
        //             <div style={{display:"flex", height:"10px", marginLeft:"40px", marginTop:"10px"}}>
        //                 <div style={{ width:"10px", 
        //                 height:"10px",marginRight:"10px", backgroundColor:"#C9CAE3"}}></div>
        //                 <p style={{color:"#6D737C", textAlign:"start"}}>2020</p>
        //             </div>
        //             <div style={{display:"flex", height:"10px", marginLeft:"40px", marginTop:"10px"}}>
        //                 <div style={{ width:"10px", 
        //                 height:"10px",marginRight:"10px", backgroundColor:"#9791AC"}}></div>
        //                 <p style={{color:"#6D737C", textAlign:"start"}}>2021</p>
        //             </div>
        //         </div>
        //     </div>
        //     {/* //segundo Item */}
        //     <div  
        //         style={{ 
        //             paddingInlineStart:"20px" }}
        //     >
        //         <div style={{display:"flex", paddingBottom:"2px", marginBottom: "-10px"}}>
        //             <input type="checkbox" style={{ marginTop:"22px"}} onChange={toggleGroup?.bind(this, 'SGIA')} checked={groupState['SGIA']} />
        //             <p style={{color:"#002445", fontWeight: "800", }}>SGIA</p>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //                 <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}} onChange={toggleLayer?.bind(this, 'PTAR', null)} checked={showLayer?.['PTAR']} />
        //                 <div
        //                     style={{ width:"10px", 
        //                         borderRadius:"30px 30px 30px ", 
        //                         marginTop:"6px",
        //                         height:"10px", 
        //                         marginRight:"6px", 
        //                         backgroundColor:"#50667C", 
        //                         marginLeft:"5px"
        //                         }}
        //                     >
        //                 </div>
        //                 <p style={{color:"#50667B", margin:0}}>PTAR</p>
        //             </div>
        //         </div>
        //     <div>
        //         <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //             <input type="checkbox" style={{ marginLeft:"20px"}} onChange={toggleLayer?.bind(this, 'ControldeMalezalineal', null)} checked={showLayer?.['ControldeMalezalineal']} />
        //             <div
        //                 style={{ width:"10px", 
        //                     borderRadius:"30px 30px 30px ",
        //                     marginTop:"6px",
        //                     height:"10px", 
        //                     marginRight:"6px",
        //                     backgroundColor:"#377EDD", 
        //                     marginLeft:"5px"
        //                     }}
        //             >
        //             </div>
        //             <p style={{color:"#50667B", margin:0}}>Control de Maleza lineal</p>
        //         </div>
        //     </div>
        //     <div>
        //         <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //             <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}} onChange={toggleLayer?.bind(this, 'ControldeMaleza', null)} checked={showLayer?.['ControldeMaleza']}  />
        //             <div
        //                     style={{ width:"10px",
        //                         marginTop:"10px",
        //                         height:"2px", 
        //                         marginRight:"6px", 
        //                         backgroundColor:"#377EDD", 
        //                         marginLeft:"5px"
        //                     }}
        //                 >
        //                 </div>
        //                 <p style={{color:"#50667B", margin:0}}>Control de Maleza</p>
        //         </div>
        //     </div>
        //     <div>
        //         <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //             <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}}  onChange={toggleLayer?.bind(this, 'Colectores', null)} checked={showLayer?.['Colectores']} />
        //             <div
        //                     style={{ width:"10px", 
        //                         marginTop:"10px",
        //                         height:"2px", 
        //                         marginRight:"6px", 
        //                         backgroundColor:"#19D090", 
        //                         marginLeft:"5px"
        //                     }}
        //                 >
        //                 </div>
        //                 <p style={{color:"#50667B", margin:0}}>Colectores</p>
        //             </div>
        //         </div>
        //     </div>
        //         {/* tercer Item  */}
        //     <div  
        //         style={{ 
        //         paddingInlineStart:"20px" }}
        //     >
        //      <div style={{display:"flex", paddingBottom:"2px", marginBottom: "-10px"}}>
        //             <input type="checkbox" style={{ marginTop:"22px"}} onClick={toggleGroup?.bind(this, 'SADER')} checked={groupState['SADER']} />
        //             <p style={{color:"#002445", fontWeight: "800"}}>SADER</p>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //                 <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}}  onChange={toggleLayer?.bind(this, 'Rastros', null)} checked={showLayer?.['Rastros']}/>
        //                 <p style={{color:"#50667B", margin:0}}>Rastros</p>
        //             </div>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //                 <input type="checkbox" style={{ marginLeft:"20px"}}  onChange={toggleLayer?.bind(this, 'PID', null)} checked={showLayer?.['PID']}/>
        //                 <p style={{color:"#50667B", margin:0}}>PID</p>
        //             </div>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //                 <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}}  onChange={toggleLayer?.bind(this, 'LaboratorioOrganismo', null)} checked={showLayer?.['LaboratorioOrganismo']}/>
        //                 <p style={{color:"#50667B", margin:0, textAlign: "initial"}}>Laboratorio de organismos Beneficios</p>
        //             </div>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //                 <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}}  onChange={toggleLayer?.bind(this, 'CAT', null)} checked={showLayer?.['CAT']}/>
        //                 <p style={{color:"#50667B", margin:0}}>CAT</p>
        //             </div>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //                 <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}} onChange={toggleLayer?.bind(this, 'Porcicola', null)} checked={showLayer?.['Porcicola']}/>
        //                 <p style={{color:"#50667B", margin:0}}>Porcicola</p>
        //             </div>
        //         </div>
        //     </div>
        //     {/* cuarto Item  */}
        //     <div  
        //         style={{ 
        //         paddingInlineStart:"20px" }}
        //         >
        //       <div style={{display:"flex", paddingBottom:"2px", marginBottom: "-10px"}}>
        //             <input type="checkbox" style={{ marginTop:"22px"}} onClick={toggleGroup?.bind(this, 'SEMADET')} checked={groupState['SEMADET']} />
        //             <p style={{color:"#002445", fontWeight: "800"}}>SEMADET</p>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //                 <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}} onChange={toggleLayer?.bind(this, 'Sitiosdedisposicinfinal', null)} checked={showLayer?.['Sitiosdedisposicinfinal']} />
        //                 <p style={{color:"#50667B", margin:0, textAlign: "initial"}}>Sitios de disposición final</p>
        //             </div>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //             <input type="checkbox" style={{ marginLeft:"20px"}}  onChange={toggleLayer?.bind(this, 'CierreAbandonoSanIgnacio', null)} checked={showLayer?.['CierreAbandonoSanIgnacio']} />
        //                 <p style={{color:"#50667B", margin:0, textAlign: "initial"}}>Cierre y abandono de Vertedero San Ignacio Cerro Gordo</p>
        //             </div>
        //         </div>
        //         <div>
        //             <div style={{display:"flex", paddingBottom:"5px", paddingTop:"5px"}}>
        //             <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px"}} onChange={toggleLayer?.bind(this, 'PCAVCertificadasFeb2020', null)} checked={showLayer?.['PCAVCertificadasFeb2020']} />
        //                 <p style={{color:"#50667B", margin:0, textAlign: "initial"}}>PCAV Certificadas Feb2020</p>
        //             </div>
        //         </div>
        //         <div style={{marginBottom:"45%", marginBottom:"20%"}}>
        //             <div style={{display:"flex", paddingBottom:"50%", paddingTop:"5px"}}>
        //                 <input type="checkbox" style={{ marginLeft:"20px", marginTop:"5px",}}  onChange={toggleLayer?.bind(this, 'PCAVRecertificadasFeb2020', null)} checked={showLayer?.['PCAVRecertificadasFeb2020']}  />
        //                 <p style={{color:"#50667B", margin:0, textAlign: "initial"}}>PCAV Recertificadas Feb2020</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default ListaEstrategia;