import React, { useState, useEffect } from 'react';

import NavbarComponentPrivate from '../../components/nabvar/NabvarComponentPrivate';
import NavbarComponent from '../../components/nabvar/NabvarComponent';
import TablaSeguimientoArchivos from "../../components/seguimientoArchivos/TablaSeguimientoArchivos";
import ChartsScreen from '../../components/chart/ChartsScreen';
import ListaEstaciones from '../../components/estaciones/ListaEstaciones';

import MapView from '../../components/mapview/MapView';

import openFile from '../../components/branding/assets/openFile.png';
import statistics from '../../components/branding/assets/statistic.png';
import {GetEstaciones, GetIcaPorAño, GetIcaPorEstacion,  GetIcaPorEstacionPorMes, GetIcaPorMes, GetIcaPorEstacionPorAño, } from "../../api/Services";

import './HomeScreenPrivate.css';
import ModalSubirArchivo from '../../components/modals/ModalSubirArchivo';
import ModalExito from '../../components/modals/ModalExito';
import outImg from '../../components/branding/assets/out.svg';
import candado from '../../components/branding/assets/candado.svg';

import * as layers from '../../data/capas';
import OutModal from '../../components/modals/OutModal';
import ResetPassword from '../../components/modals/ResetPassword';
const HomeScreen = () => {
    const [estaciones, setEstaciones] = useState("");
    const [useHeight, setHeight] = useState(window.innerHeight);
    const [useWidth, setWidth] = useState(window.innerWidth);
    const [click, setClick] = useState(true)
    const [showMoreCharts, setShowMoreCharts] = useState(true)
    const [showMoreGeneral, setShowMoreGeneral] = useState(false)
    const [clickId, setClickId] = useState('')
    const [date, setDate] = useState('');
    const [icaPorAño, setIcaPorAño] = useState('');
    const [icaPorEstacion, setIcaPorEstacion] = useState('');
    const style = { showMoreCharts, showMoreGeneral  };
    const [icaPorMes, setIcaPorMes] = useState('');
    const [ icaName, setIcaName] = useState('');
    const [showModalFile, setShowModalFile] = useState(false);
    const [uploadExito, setUploadExito] = useState(false);
    const [icaDateEstacion, setIcaDateEstacion]  = useState('');
    const [showEstrategia, setEstrategia] = useState(false);
    const [showListEstaciones, setListEstaciones] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const [showDatosGenerales, setShowDatosGenerales] = useState(true);
    const [showPublicacionesTable, setShowPublicacionesTable] = useState(false);
    const [showEstatusTable, setEstatusTable]  = useState(true);
    const [showModalOut, setShowModalOut] = useState(false);
    const [out, setOut] = useState(false)
    const [passwordReset, setPasswordReset] = useState(false)

    let estacion = {}
    let name="";
    let icaPorEstacionDelUltimoAño = "";

    for (var i = 0; i < estaciones.length; i++) {
        if (estaciones[i].id === clickId) {
            estacion = estaciones[i]
            name = estaciones[i].name
            icaPorEstacionDelUltimoAño  = estaciones[i].ica
            break;
        }
    }
    useEffect(() => {
        GetEstaciones().then(res => {
            setDate(res.date)
            setEstaciones(res.stations);
        })
        .catch()

      
    }, []);
   
    useEffect(() => {
        if(clickId && name){
            GetIcaPorEstacion(name).then(res => {
                setIcaPorEstacion(res);
            })
            .catch( (e)=>{console.log(e, 'error');})
            var e = new Date(lastYear+"/01/01")
            let count = 9;
            let mesesEstacion = [
                {
                    start :  e.getFullYear() +"-"+ (e.getMonth()+1) +"-"+ e.getDate(), 
                    end:  e.getFullYear() +"-"+ (e.getMonth()+2) +"-"+ (e.getDate()+1)
                }
            ];
        
            for (var i = 0; i <= count; i++) {
                e.setMonth(e.getMonth() + 1)
                mesesEstacion.push( {
                    start :  e.getFullYear() +"-"+ (e.getMonth()+1) +"-"+ e.getDate(), 
                    end:  e.getFullYear() +"-"+ (e.getMonth()+2) +"-"+ (e.getDate()+1)
                });
            }

            let ica = []
        Promise.all(
            [...mesesEstacion]
                .map(val =>  GetIcaPorEstacionPorMes(name, val.start, val.end))
        ).then(res => {
            [...res]?.map(function(estacion, i){
                [...estacion]?.map(function(estacion, i){
                    ica.push(estacion)
                })
            });
            setIcaDateEstacion(ica)
        }) 

        const arr_estaciones = Array.from(estaciones);
        const tieneEstaciones = arr_estaciones?.length;

        if ( tieneEstaciones ) {
            Promise.all(
                arr_estaciones
                    .filter(v => !!v.name)
                    .map(val => GetIcaPorEstacionPorAño(val.name, 2018))
            ).then(res => {

                const icas = res.map(estacion => {
                    const valor = estacion.pop().ica;
                    return valor;
                });
                setIcaName(icas)
            })            
        }
        }

        

        GetIcaPorMes().then(res => {
            setIcaPorMes(res);
        })
        .catch()

        GetIcaPorAño()
        .then(res => {
            setIcaPorAño(res);
        })
        .catch( (e)=>{console.log(e, 'error');})

        const handleResize = () => {
            setHeight(window.innerHeight);
            setWidth(window.innerWidth);
        };
      
        window.addEventListener("resize", handleResize);
        return () => { window.removeEventListener("resize", handleResize); };
 
    }, [clickId, estaciones]);

    const showMore = () => { setShowMoreCharts(!showMoreCharts); }
    const showMoreGeneralData= () =>{ setShowMoreGeneral(!showMoreGeneral);}
    
    const arrIcaPorAño = [...icaPorAño]?.map(function(val, i){
        return val.year;
    });

    let  lastYear = Math.max.apply( Math, arrIcaPorAño );

    const arrIcaPorMes = [...icaPorMes]?.map(function(val, i){
         return val;
    });

    let antepenultimoAñoValue = [...icaPorAño]?.filter((item) => { 
        return item.year === lastYear -1;
    })


    let icaConMesesDelUltimoAño = [...arrIcaPorMes]?.filter((item) => {
        return item.year === lastYear;
    });

    let icaConMesesDelAntepenultimoAño = [...arrIcaPorMes]?.filter((item) => {
        return item.year === lastYear -1;
    });

    const [showLayer, setLayer] = useState(Object.keys(layers).reduce((a, key) => {
        a[key] = false;
        return a;
    }, {}));

    const toggleLayer = (layerName, state = null) => {
        let newState = {...showLayer};

        newState[layerName] = state !== null
            ? state
            : !newState[layerName];

        setLayer(newState);
    }

   
    const clickOut = ()=>{
        setOut(!out)
    } 
    
    const clickResetPassword = () =>{
        setPasswordReset(!passwordReset)
    }
    return (           
        <>
        {uploadExito && !showModalFile && 
            <ModalExito
                uploadExito={uploadExito} 
                setUploadExito={setUploadExito}
            />
        }
        {showModalFile && !uploadExito && 
            <ModalSubirArchivo
                showModalFile={showModalFile}
                setShowModalFile={setShowModalFile}
                uploadExito={uploadExito} 
                setUploadExito={setUploadExito}
            /> 
        }
        {out && 
            <OutModal
                setOut={setOut}
                out={out}
                uploadExito={uploadExito} 
            /> 
        }
        {passwordReset &&
            <ResetPassword 
                setOut={setOut}
                out={out}
                uploadExito={uploadExito} 
                passwordReset={passwordReset} 
                setPasswordReset={setPasswordReset}
            />
        }
        {!uploadExito && !showModalFile && 
        <>
        {showModalOut && !out && !passwordReset &&
        <div className="sessionOutContainer" 
            style={{
                backgroundColor:"#FFFFFF",
                width:"276px", height:"133px", 
                position:"absolute", 
                display:"table-column",
                justifyContent:"space-between",
                marginLeft:"25%",
                right:0,
                borderRadius:"10px 10px 10px"
            }} 
        >
            <div 
                style={{display:"flex",  
                    borderBottom: "2px solid #BCBCBC",
                    textAlign:"center", height:"66px"
                }}
            >
                <p style={{paddingLeft:'15%', color:"#022445"}}>Cambiar contraseña</p>
                <img width="40px" 
                    alt="Mostrar gráficas"
                    className="out" 
                    style={{paddingRight: "30px", right:0}}
                    src={candado} 
                    onClick={clickResetPassword} 
                />
            </div>
            <div style={{display:"flex", height:"66px"}}>
                <p style={{ paddingLeft:'15%', color:"#022445" } }>Cerrar sesión</p>
                    <img  
                        width="31.5px"
                        alt="Mostrar gráficas" 
                        className="key" 
                        onClick={clickOut} 
                        style={{paddingRight: "30px", right:0, color:"#AB7C94" }}
                        src={outImg} 
                    />
            </div>
        </div>
        }
        {!out &&  !passwordReset &&<>
            <div className="container">
                <NavbarComponentPrivate 
                    useWidth={useWidth} click={click} 
                    setClick={setClick} 
                    showEstrategia={showEstrategia}
                    setEstrategia={setEstrategia}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    privateView={true}
                    useWidth={useWidth} 
                    click={click} 
                    setClick={setClick} 
                    showDatosGenerales={showDatosGenerales}
                    setShowDatosGenerales={setShowDatosGenerales}
                    showPublicacionesTable={showPublicacionesTable}
                    setShowPublicacionesTable={setShowPublicacionesTable}
                    showEstatusTable={showEstatusTable}
                    setEstatusTable={setEstatusTable}
                    showModalOut={showModalOut}
                    setShowModalOut ={setShowModalOut}
                />
                        
      
            </div>
            <div className="screen" style={{ height: useHeight }}>
                <div className="bodyScreen">
                    <div className="cardMap" style={{ transition: "background-color 1s ease 0s" }}>
                        {useWidth < 420 && !click && 
                            <ListaEstaciones 
                                useWidth={useWidth} 
                                useHeight={useHeight} 
                                estaciones={estaciones} 
                                click={click} 
                                setClick={setClick} 
                                clickId={clickId} 
                                setClickId={setClickId} 
                                date={date}
                                hiddenElement={true}
                                label={"Estaciones de medición"}
                                privateView={true}
                            />
                        }
                        {useWidth > 420 && click && 
                            <ListaEstaciones 
                                useWidth={useWidth} 
                                useHeight={useHeight} 
                                estaciones={estaciones} 
                                click={click} 
                                setClick={setClick} 
                                clickId={clickId} 
                                setClickId={setClickId} 
                                date={date} 
                                hiddenElement={true}
                                label={"Estaciones de medición"}
                                privateView={true}
                            />
                        }
                        {click && !showEstrategia && <div className="mapCont">
                        <div className="mapContainerPrivate">
                        <MapView showLayer={showLayer} estacion={estacion} click={clickId} privateView={false}></MapView> 
                        </div>
                        </div>}
                    </div>
                    {useWidth > 800 &&  !showModalOut &&
                        <div className="iconsContainer" >
                            <img width="31.5px" 
                                alt="Mostrar gráficas" 
                                className="showMoreStatistic" 
                                style={style.showMoreCharts && style.showMoreGeneral 
                                    ? { marginLeft: "18.5%", backgroundColor:'#0E5FC5' } 
                                    : { marginLeft: "9%", backgroundColor:'#0E5FC5' }
                                }
                                src={statistics} 
                                onClick={showMoreGeneralData} 
                            />
                                
                            <img width="31.5px" 
                                alt="Mostrar gráficas" 
                                className="showMoreFiles" 
                                onClick={showMore} 
                                style={style.showMoreCharts && style.showMoreGeneral  
                                    ? { marginLeft: "18.5%", backgroundColor:'#0E5FC5', marginTop: '10px' } 
                                    : { marginLeft: "9%", backgroundColor:'#0E5FC5' }
                                }
                                src={openFile} />
                        </div> }
                        {click  && !showModal && !showModalOut &&
                        <div className="viewConponentTable" 
                            // style={style.showMoreCharts  
                            //     ? {display:'none'} 
                            //     : {display:'block'}}
                                 >
                            <TablaSeguimientoArchivos 
                                showModalFile={showModalFile}
                                setShowModalFile={setShowModalFile}
                                useHeight={useHeight} 
                                useWidth={useWidth} 
                                showMoreCharts={showMoreCharts} 
                                setShowMoreCharts={setShowMoreCharts} 
                                estacion={estacion} 
                                click={clickId}
                                marginTop={"80px"}
                                showDatosGenerales={showDatosGenerales}
                                setShowDatosGenerales={setShowDatosGenerales}
                                showPublicacionesTable={showPublicacionesTable}
                                setShowPublicacionesTable={setShowPublicacionesTable}
                                showEstatusTable={showEstatusTable}
                                setEstatusTable={setEstatusTable}
                            ></TablaSeguimientoArchivos>
                        </div>
}
                        {click && !showEstrategia && !showModal &&  !showMoreGeneral && !showModalOut &&
                        <div className="chartDataWrapper" >
                            <ChartsScreen 
                                icaValueChart={icaPorAño}
                                icaPorEstacionDelUltimoAño={icaPorEstacionDelUltimoAño}
                                icaPorEstacion={icaPorEstacion}
                                icaConMesesDelUltimoAño={icaConMesesDelUltimoAño}
                                icaConMesesDelAntepenultimoAño={icaConMesesDelAntepenultimoAño}
                                estacionPorNombreIcaAño={icaName}
                                estaciones={estaciones} 
                                useHeight={useHeight} 
                                useWidth={useWidth}  
                                estacion={estacion} 
                                click={clickId}
                                date={date} 
                                privateView={true}
                                marginTop={"72px"}
                                icaPorMesEstacionUltimoAno={icaDateEstacion}
                                antepenultimoAñoValue={antepenultimoAñoValue} />
                           
                        </div>
                        }
                </div>
            </div></>}
       </>  } </>
    )
}

export default HomeScreen;
