import "./App.css";
import HomeScreen from "./views/HomeScreen/HomeScreen";
import EstrategiaScreen from "./views/EstrategiaScreen/HomeScreen";
import HomeScreenPrivate from "./views/HomeScreenPrivate/HomeScreenPrivate"

import ModalLogin from './views/Login/ModalLogin';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import "leaflet/dist/leaflet.css";
import '../src/views/HomeScreen/HomeScreen.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={HomeScreen}/>
          <Route path="/estrategia" exact component={EstrategiaScreen}/>
          <Route  path="/login" exact component={ModalLogin}/>
          <Route  path="/gestion" exact component={HomeScreenPrivate}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
