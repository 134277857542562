import React from 'react';
import Exito from '../branding/assets/Exito.svg';

import './ModalSubirArchivo.css';

const ModalExito = ({uploadExito, setUploadExito}) =>{

  const Aceptar = () =>{
    setUploadExito(!uploadExito)
  }

  return (
    <div className="modalContainer">
      <div className="boxModal">
        <div className="imgHeader">
        </div>
        <img 
            src={ Exito} 
            fill="white"
            alt="lista de Estaciones" 
            width={"35%"} height={"50%"} 
            style={{
              textColor: '#82AF84'
            }}
          />
          <h3 className="labelExitoModal">Acción realizada con éxito</h3>
          <div className="buttonOkModalExito" 
            style={{backgroundColor:"#959595", color:"#FFFFFF"}}
            onClick={Aceptar}>Aceptar</div>
      </div>
    </div>
  )
}

export default ModalExito;