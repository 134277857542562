import React, { useState, useEffect, } from "react";
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap, GeoJSON, LayersControl} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import subcuencas from "../../data/rio_santiago_subcuencas.zip";
import PuntosDeMuestreoCEA from '../../data/PuntosDeMuestreoCEA.kml';
import ReactLeafletKml from 'react-leaflet-kml';
import RioSantiago from '../../data/RioSantiago.kml';
import PuntosDeMuestreoSantiago from '../../data/PuntosDeMuestreoSantiago.kml';
import markerExcelenteShadow from '../../components/branding/assets/SVGicons/Excelent.svg';
import markerBuenaShadow from '../../components/branding/assets/SVGicons/Buena_1.svg';
import markerMediaShadow from '../../components/branding/assets/SVGicons/Media_1.svg';
import markerMalaShadow from '../../components/branding/assets/SVGicons/Mal.svg';
import markerMuyMalaShadow from '../../components/branding/assets/SVGicons/Mal.svg';

import L from "leaflet";
import "leaflet/dist/leaflet.css";

import PropTypes from "prop-types";
import shp from "shpjs";

import * as layers from '../../data/capas';

function Shapefile({ zipUrl }) {
    const map = useMap();

    useEffect(() => {
        const geo = L.geoJson(
            { features: [] }, {
            onEachFeature: function popUp(f, l) {
                var out = [];
                if (f.properties) {
                    for (var key in f.properties) {
                        out.push(key + ": " + f.properties[key]);
                    }
                    l.bindPopup(out.join("<br />"));
                }
            }
        }
        ).addTo(map);

        shp(zipUrl).then(function (data) {
            geo.addData(data);
        });

    }, [zipUrl, map]);

    return null;
}

Shapefile.propTypes = {
    zipUrl: PropTypes.string.isRequired
};

const POSITION_CLASSES = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
};

const MapYearSelect = (props) => {
    const { position } = props;
    return (
        <div >
        <div className={"mapDropdown leaflet-control"} style={{
            height:"50px",
            display:"flex",
            justifyContent:"space-around", 
            width:"200px",
            paddingInline:"20px"
            
        }}>
            <div style={{display:"flex", paddingTop:"10px", paddingBottom:"10px"}}>
                <img width="20px"  height="22px"  alt="Archivo" style={{marginInline:"5px"}}
                src="https://image.flaticon.com/icons/png/512/684/684908.png" />
                <p style={{color:"gray"}}>ESTACION</p>
            </div>
            <div style={{display:"flex", paddingTop:"10px", paddingBottom:"10px", marginLeft:"15px"}}>
            <img width="20px" height="25px" alt="Archivo" style={{marginInline:"15px", marginRight:"8px", marginLeft:"5px"}}
                src="https://image.flaticon.com/icons/png/512/1157/1157001.png" />
                <p style={{color:"gray"}}>MUESTREO</p>
            </div>
        </div> 
    </div>
        // <div className={POSITION_CLASSES[position]}>
        //     <div className={"mapDropdown leaflet-control"}>
        //         <input id="yearSelect" type="checkbox" />
        //         <label className="dropdownLegend" htmlFor="yearSelect">
        //             Valores promedio por año
        //         </label>
        //         <label className="dropdownYear" htmlFor="yearSelect">
        //             {active}
        //         </label>
        //         <ul>{children}</ul>
        //     </div> 
        // </div>
    );
};

var MyIcon = L.icon({
    iconUrl: 'https://image.flaticon.com/icons/png/512/595/595750.png',
    iconSize: [30, 32],
    iconAnchor: [8, 18],
    popupAnchor: [0, -10],
    shadowSize: [0, 0],
    shadowAnchor: [10, 10]
});

function MapView({ estacion, click, showLayer, estaciones, setClick, setClickId }) {
    const [puntosMuestreo, setPuntosMuestreo] = useState(null);
    const [rioSantiagoPolilinea, setRioSantiagoPolilinea] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());

    let markers;
    let estacionesGenerales= [];
    let allMarkers;

    const iconFromValue = (value, shadow)=>{
        const classes = [
            {label: "Muy mala", icon: shadow === true ? markerMuyMalaShadow :  "/icons/Muymala@2x.png"},
            {label: "Mala", icon: shadow === true ? markerMalaShadow : "/icons/Mala@2x.png"},
            {label: "Media", icon: shadow === true ? markerMediaShadow :"/icons/Media@2x.png"},
            {label: "Buena", icon: shadow === true ? markerBuenaShadow : "/icons/Buena@2x.png"},
            {label: "Excelente", icon: shadow === true ? markerExcelenteShadow : "/icons/Muymala@2x.png"},
        ];
        let clasificacion = 0;
        if(25 < value && value <= 50) clasificacion=1;
        if(50 < value && value <= 70) clasificacion=2;
        if(70 < value && value <= 90) clasificacion=3;
        if(90 < value && value <= 100) clasificacion=4;

        return classes[clasificacion];

    }

	if (typeof estacion.location !== 'undefined') {
        if(estacion.location){
            markers = [{
                location: estacion.location.split(';'),
                icon: L.icon({
                    iconUrl: iconFromValue(estacion.ica, false).icon,
                    iconSize: [30, 32],
                    iconAnchor: [8, 18],
                    popupAnchor: [0, -10],
                    shadowSize: [0, 0],
                    shadowAnchor: [10, 10]
                })
            }];
        }
	} else {
		markers = [];
	}

    for (let i = 0; i < estaciones.length; i++) {
        if(estacion && estacion.location && estacion.id === estaciones[i].id) continue;
        if(!estaciones[i].location) continue;
        const longlat = estaciones[i].location.split(';');
        if(longlat.length !== 2) continue;
        allMarkers = [{
            location: estaciones[i].location.split(';'),
            icon: L.icon({
                iconUrl: iconFromValue(estaciones[i].ica, true).icon,
                iconSize: [25, 32],
                iconAnchor: [8, 18],
                popupAnchor: [0, -10],
                shadowSize: [0, 0],
                shadowAnchor: [10, 10]
            }),
            id: estaciones[i].id
        }];
        estacionesGenerales.push(allMarkers);
    }

    useEffect(() => {
        fetch(PuntosDeMuestreoSantiago)
          .then((res) => res.text())
          .then((kmlText) => {
            const parser = new DOMParser();
            const kmlPuntosMuestreo = parser.parseFromString(kmlText, "text/xml");
            setPuntosMuestreo(kmlPuntosMuestreo);
            
        });

        fetch(RioSantiago)
          .then((res) => res.text())
          .then((kmlText) => {
            const parser = new DOMParser();
            const kmlRioSantiago = parser.parseFromString(kmlText, "text/xml");
            setRioSantiagoPolilinea(kmlRioSantiago);
          });
    
    }, []);
   
    const changeYear = (year) => {
        setYear(year);
        document.getElementById("yearSelect").checked = false;
    };

    let lis = [];

    for (
        let currentYear = new Date().getFullYear();
        currentYear >= new Date().getFullYear() - 4;
        currentYear--
    ) {
        lis.push(
            <li
                className={currentYear === year ? "activeYear" : ""}
                key={currentYear}
                onClick={() => changeYear(currentYear)}>
                {currentYear}
            </li>
        );
    }

    return (
        <MapContainer 
            center={[20.6595, -103.3494]}
            zoom={10}
            zoomControl={false}
            attributionControl={false}
        >
            <ZoomControl position="bottomleft"></ZoomControl>
            <Shapefile zipUrl={subcuencas} />
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="//stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png"
            />
            {rioSantiagoPolilinea && 
                <ReactLeafletKml kml={rioSantiagoPolilinea} />
            }
            {Object.keys(showLayer).map(key => {
                return showLayer[key] && <GeoJSON data={layers[key]} pointToLayer={(feature, latlng) => {
                    return L.marker(latlng, {icon: MyIcon})
                }} />
            })}
            {click ?
                <div>
                    {markers?.map((position, idx) =>
                        <Marker key={`marker-${idx}`} position={position.location} icon={position.icon}></Marker>
                    )}
                </div> 
                : ""
            } 
            {estacionesGenerales?.map((position, idx) =>
                <Marker
                    key={`marker-${idx}`} 
                    position={position[0].location} 
                    icon={position[0].icon} 
                    eventHandlers={{
                        click: (e) => {
                            if(setClickId){
                                setClickId(position[0].id);
                            }
                            if(setClick){
                                setClick(true);
                            }
                        },
                    }}
                    />
            )}
        </MapContainer>
    );
}

export default MapView;
