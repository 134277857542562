import React from 'react';

import Logo from '../branding/logo/Logo';

import LogoJalisco from '../branding/assets/logoJalisco.svg';
import LogoRioSantiago from '../branding/assets/logoRioSantiago.svg';
import LogoRioSantiagoBlancoAzul from '../branding/assets/logoRioSantiagoBlancoAzul.svg';
import cicloCalidad from '../branding/assets/cicloCalidad.png';
import cicloEstrategia from '../branding/assets/cicloEstrategia.png';
import deleteIcon from '../branding/assets/delete.svg';
import menuPoint from '../branding/assets/menuPoint.png';
import Arrow from '../branding/assets/arrow.svg';

import './NabvarComponent.css';

const NavbarComponent = (
  {
    useWidth, 
    click, 
    setClick,  
    showEstrategia, 
    setEstrategia, 
    showModal, 
    setShowModal,
    privateView,
  }) => {

  const style = { click };

  const changeColor = () => {
    setClick(!click);
    setEstrategia(false);
  }

  const switchMenuState = () => {
    setEstrategia(!showEstrategia); 
    setClick(true);
  }

  const switchShowModal = () => {
    setShowModal(!showModal);
  }

  return (
      <>
        { useWidth < 800 &&
          <div style={{backgroundColor:"#8ACCE2"}} >
            {showModal &&
              <div style={{backgroundColor:"#002445", height: "280px", borderRadius: "10px "}}>
                <div style={{
                  justifyContent: "space-between",
                  paddingTop:"20px",
                  marginInline: "20px",
                  display: "flex"
                  }}
                >
                  <Logo 
                    img={ LogoRioSantiagoBlancoAzul} 
                    width={"35%"} 
                    height={"25%"}  
                    style={{
                      marginTop: "3%",
                      marginleft: "5%"
                    }}
                  />
                  <img 
                    className="closeButton"
                    src={deleteIcon} 
                    fill="white"
                    alt="lista de Estaciones" 
                    width={"7%"} height={"7%"} 
                    style={{
                      textColor: 'red'
                    }}
                    onClick={switchShowModal}
                  />
                </div> 
              <div 
                className="divTitleBlue"
                onClick={switchMenuState}
                style={{backgroundColor: '#005FC5', marginTop:"40px"}}
              >
                <img 
                  width="25px" height="25px"
                  style={{marginRight:"15px", marginLeft:"10px", paddingTop: "8px"}}
                  src={ cicloEstrategia}
                />
                <p>Estrategia</p>       
              </div>
              <div 
                className="divTitleBlue" 
                style={{
                  backgroundColor: '#005FC5', 
                  marginTop:"100px"
                }}
                onClick={changeColor}
              >
                <img 
                  width="25px" 
                  height="25px"
                  style={{
                    marginRight:"15px", 
                    marginLeft:"10px", 
                    paddingTop: "8px"
                  }}
                  src={cicloCalidad}  
                />
                <p>Calidad del agua</p>
              </div>
            </div>
            }
            {!showModal &&         
              <div>
                <div className="navSmallScreen" style={{backgroundColor:"#8ACCE2",}}>
                  <div>
                    <Logo img={LogoRioSantiagoBlancoAzul} width={"50%"} height={"100%"} />
                  </div>
                  <div className="fullNavSmallScreen">
                    <Logo 
                      img={LogoJalisco} 
                      width={40} 
                      height={40} 
                    />
                    <img 
                      src={menuPoint}
                      width={"25%"} 
                      height={"90%"} 
                      style={{
                        paddingBottom:"3%"
                      }}
                      onClick={switchShowModal}
                    />
                  </div>   
                </div>
                {!privateView &&
                <div className="containerselector">
                  <div className="box" style={{ backgroundColor: "#005FC5", width:"50%"}}
                    onClick={switchMenuState}>
                    <p>Estrategia</p>
                  </div>
                  <Logo img={LogoJalisco} width={40} height={40} styles={styles.logoJalisco} />
                  <div className="munuOptions" onClick={changeColor}>
                    <p>Estaciones</p>
                    <img src={Arrow} alt="lista de Estaciones" width={"10%"} height={"35%"} className="downChevron" style={{ transform: style.click ? 'rotate(0deg)' : 'rotate(180deg)', transition: style.click ? "transform 0.5s ease 0s" : "" }} />
                  </div>
                </div>}
              </div>
            }
          </div> 
        }
      { useWidth > 800 &&
        <div>
          <div className="boxDesktop" style={{ backgroundColor: style.click ? "#8ACCE2" : "#3580CD" }}>
            <Logo img={LogoRioSantiago} width={"50%"} height={"100%"} />
            <Logo img={LogoJalisco} width={65} height={65} styles={styles.logoJaliscoDesktop} />
          </div>
        </div>
      }
    </>
  )
}

const styles = {
  logoJalisco: {
    with: "10%",
    marginInline: "44.5%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1%"
  },
  logoJaliscoDesktop: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2%"
  }
};

export default NavbarComponent;